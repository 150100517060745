.App {
  text-align: center;
  color: #333;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1 {
  font-weight: normal;
}

.doc {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: 3em;
  align-items: center;
  align-content: center;
}

.desc {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 2em;
  margin-top: -10px;
}

.desc p {
  max-width: 800px;
  font-size: 20px;
}

.page {
  border: 1px solid #efefef;
  background: white;
  max-width: 100%;
  margin-bottom: 30px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
